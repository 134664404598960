<template>
  <div>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script lang="ts">
import "@/styles/overrides.sass";

import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
});
</script>
