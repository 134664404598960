export const DASHBOARD_ROUTE = {
  title: "Dashboard",
  icon: "mdi-view-dashboard",
  to: "/",
  roles: [
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_STAFF_SALES",
    "ROLE_STAFF_COORDINATE_OPERATIONS",
  ],
};

export const CALENDAR_ROUTE = {
  title: "Calendario",
  icon: "mdi-calendar",
  to: "/calendar/",
  roles: [
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_STAFF_SALES",
    "ROLE_STAFF_OPERATIONS",
    "ROLE_STAFF_COORDINATE_OPERATIONS",
  ],
};

export const ORDER_ROUTE = {
  title: "Ordenes de Servicio",
  icon: "mdi-toolbox-outline",
  to: "/orders/",
  roles: [
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_STAFF_SALES",
    "ROLE_STAFF_OPERATIONS",
    "ROLE_STAFF_COORDINATE_OPERATIONS",
  ],
};

export const QUOTE_ROUTE = {
  title: "Cotizaciones",
  icon: "mdi-file-document-outline",
  to: "/quotes/",
  roles: [
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_STAFF_SALES",
    "ROLE_STAFF_COORDINATE_OPERATIONS",
  ],
};

export const BREAK_FIXES_ROUTE = {
  title: "Soluciones de Quiebre",
  icon: "mdi-tools",
  to: "/break-fixes/",
  roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_STAFF_SALES"],
};

export const PACKAGING_ROUTE = {
  title: "Embalajes",
  icon: "mdi-package-variant",
  to: "/packaging/",
  roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_STAFF_SALES"],
};

export const AVAILABILITY_ROUTE = {
  title: "Disponibilidad",
  icon: "mdi-calendar-clock",
  to: "/availability/",
  roles: ["ROLE_USER", "ROLE_ADMIN", "ROLE_STAFF_SALES"],
};

export const VISIT_ROUTE = {
  title: "Info. de Visitas",
  icon: "mdi-file-document-outline",
  to: "/quotes/",
  roles: ["ROLE_STAFF_VISIT_MOVING"],
};

export const VISITS_ROUTE = {
  title: "Visitas Programadas",
  icon: "mdi-map-marker",
  to: "/visits/",
  roles: [
    "ROLE_USER",
    "ROLE_ADMIN",
    "ROLE_STAFF_SALES",
    "ROLE_STAFF_VISIT_MOVING",
  ],
};

export const USERS_ROUTE = {
  title: "Usuarios",
  icon: "mdi-account-group",
  to: "/users/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const TARIFFS_ROUTE = {


  
  title: "Tarifas",
  icon: "mdi-currency-usd",
  to: "/tariffs/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const ARTICLES_ROUTE = {
  title: "Objetos",
  icon: "mdi-archive",
  to: "/articles/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const COSTS_ROUTE = {
  title: "Costos",
  icon: "mdi-currency-eur",
  to: "/costs/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const TASKS_ROUTE = {
  title: "Servicios Adicionales",
  icon: "mdi-plus",
  to: "/tasks/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const REASONS_ROUTE = {
  title: "Razón de no venta",
  icon: "mdi-close",
  to: "/reasons/",
  roles: ["ROLE_ADMIN", "ROLE_USER", "ROLE_STAFF_SALES"],
};

export const EXPENDITURES_ROUTE = {
  title: "Egresos",
  icon: "mdi-cash-register",
  to: "/expenditures/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const REPORTS_ROUTE = {
  title: "Reportes",
  icon: "mdi-file-table",
  to: "/reports/",
  roles: ["ROLE_ADMIN", "ROLE_USER"],
};

export const NOTIFICATIONS_ROUTE = {
  title: "Notificaciones",
  icon: "mdi-bell",
  to: "/notifications/",
  roles: ["ROLE_USER", "ROLE_ADMIN"],
};

export const APP_ROUTES = [
  DASHBOARD_ROUTE,
  CALENDAR_ROUTE,
  ORDER_ROUTE,
  QUOTE_ROUTE,
  BREAK_FIXES_ROUTE,
  PACKAGING_ROUTE,
  AVAILABILITY_ROUTE,
  VISIT_ROUTE,
  VISITS_ROUTE,
  USERS_ROUTE,
  TARIFFS_ROUTE,
  ARTICLES_ROUTE,
  COSTS_ROUTE,
  TASKS_ROUTE,
  REASONS_ROUTE,
  EXPENDITURES_ROUTE,
  REPORTS_ROUTE,
];