<template>
  <v-app>
    <default-bar />

    <default-drawer
      :drawer.sync="drawer"
      :drawer-image="drawerImage"
      :mini.sync="mini"
      :items="items"
    />

    <default-view />
    <ReloadPrompt />

    <v-dialog v-model="openModal" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Atención</span>
        </v-card-title>
        <v-card-text> La sesión ha expirado. </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" block @click="closeExpiredSession"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore } from "@/stores/app";
import ReloadPrompt from "@/components/ReloadPrompt.vue";
import DefaultBar from "./AppBar.vue";

import DefaultDrawer from "./Drawer.vue";
import DefaultView from "./View.vue";

export default defineComponent({
  name: "DefaultLayout",

  components: {
    DefaultBar,
    DefaultDrawer,
    DefaultView,
    ReloadPrompt,
  },

  setup() {
    const openModal = ref(false);
    const appStore = useAppStore();
    const { items, drawer, drawerImage, mini } = storeToRefs(appStore);
    return {
      items,
      drawer,
      drawerImage,
      mini,
      openModal,
    };
  },

  watch: {
    "$auth.expiresAt": {
      immediate: true,
      handler(expiresAt?: number): void {
        if (expiresAt) {
          setTimeout(
            () => {
              if (this.$auth.expiresAt) {
                this.openModal = true;
              }
            },
            expiresAt - Date.now() - 1000,
          );
        }
      },
    },
  },
  methods: {
    closeExpiredSession(): void {
      this.openModal = false;
      this.$auth.logout();
    },
  },
});
</script>
