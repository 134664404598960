<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-avatar tile>
      <v-img :src="logo" />
    </v-list-item-avatar>

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-bold">Movify</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script lang="js">
import logo from "@/assets/logo.svg";

export default {
  name: "DefaultDrawerHeader",
  data: () => ({
    logo,
  }),
};
</script>
