<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { computed } from "vue";
import { ref, watch } from "vue";

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
const isOpen = ref(false);
const showSnackbar = computed(() => {
  return offlineReady.value || needRefresh.value;
});
watch(
  showSnackbar,
  (value) => {
    isOpen.value = value;
  },
  { immediate: true },
);
const close = async () => {
  offlineReady.value = false;
  needRefresh.value = false;
};
</script>

<template>
  <v-snackbar
    v-model="isOpen"
    right
    bottom
    color="primary"
    multi-line
    timeout="0"
    outlined
    text
    vertical
  >
    <template v-if="offlineReady">
      App lista para trabajar sin conexión</template
    >
    <template v-else>
      Nuevo versión disponible, haga clic en el botón de recargar para
      actualizar.
    </template>
    <template #action="{ attrs }">
      <v-btn
        v-if="needRefresh"
        color="secondary"
        v-bind="attrs"
        depressed
        @click="updateServiceWorker()"
      >
        Recargar
      </v-btn>
      <v-btn text v-bind="attrs" @click="close"> Cerrar </v-btn>
    </template>
  </v-snackbar>
</template>
