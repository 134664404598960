import router from "@/router";
import { useStorage } from "@vueuse/core";
import axios from "axios";
import get from "lodash/get";
import { watch, reactive, toRefs, type UnwrapRef } from "vue";
import { authDefaultOptions } from "./constants";
import type { IConfig } from "./interfaces";
import { setUser } from "@sentry/browser";

export interface IUser {
  username: string;
  id: number;
  email: string;
  roles: string[];
}

export const createAuth = <U extends IUser = IUser>(
  options: IConfig = authDefaultOptions,
) => {
  const { redirect, strategy } = options;
  const state = reactive({
    token: useStorage<string | null>("auth.token", null),
    expiresAt: useStorage<number | null>("auth.expiresAt", null),
    isLoading: false,
    isAuthenticated: false,
    user: null as U | null,
    error: new Error("") as unknown,
  });

  async function initialize() {
    if (!strategy) {
      return;
    }

    if (!state.token) {
      return;
    }

    try {
      state.isLoading = true;
      state.user = await fetchUser() as UnwrapRef<U>;
      state.isAuthenticated = true;
    } catch (e) {
      logout();
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  }

  async function login(loginData: object, _redirect = redirect.home) {
    const config = strategy.endpoints.login;
    const response = await axios.request({
      url: config.url,
      method: config.method,
      data: loginData,
    });
    const data = response.data;
    state.token = get(data, strategy.token.property);
    state.expiresAt = get(data, "expiresAt");
    if (strategy.user.autoFetch) {
      const fetchedUser = await fetchUser();
      state.user = fetchedUser as UnwrapRef<U>; 
    }
    state.isAuthenticated = true;
    const redirectQuery = router.currentRoute.query.redirect;
    await router.push(redirectQuery?.toString() || _redirect);
    return state.token;
  }

  async function fetchUser() {
    const config = strategy.endpoints.user;
    const response = await axios.request<U>({
      url: config.url,
      method: config.method,
      headers: {
        Authorization: `Bearer ${state.token}`,
      },
    });
    const data = response.data;
    const user: U = strategy.user.property
      ? get(data, strategy.user.property)
      : data;
    if (user) {
      setUser({
        username: user?.username,
        id: user?.id,
        email: user?.email,
      });
    }
    return user;
  }

  async function logout() {
    state.token = null;
    state.isAuthenticated = false;
    setUser(null);
    await router.push(redirect.login);
  }

  initialize();

  router.beforeEach(function (to, from, next) {
    const guardAction = () => {
      if (state.isAuthenticated || to.path.includes(redirect.login)) {
        return next();
      } else {
        return next({
          path: redirect.login,
          query: { redirect: to.fullPath },
        });
      }
    };

    // If the Auth0Plugin has loaded already, check the authentication state
    if (!state.isLoading) {
      return guardAction();
    }

    const unwatch = watch(
      () => state.isLoading,
      (isLoading) => {
        if (isLoading === false) {
          unwatch();
          return guardAction();
        }
      },
    );
  });

  return {
    login,
    fetchUser,
    logout,
    ...toRefs(state),
  };
};
