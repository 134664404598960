<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="internalDrawer"
    :right="$vuetify.rtl"
    :mini-variant.sync="internalMini"
    mini-variant-width="80"
    app
    width="260"
  >
    <div class="px-2">
      <default-drawer-header />

      <v-divider class="mx-3 mb-2" />

      <default-list :items="items" />
    </div>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@vueuse/core";
import DefaultList from "./List.vue";
import DefaultDrawerHeader from "./widgets/DrawerHeader.vue";

export default defineComponent({
  name: "DefaultDrawer",

  components: {
    DefaultDrawerHeader,
    DefaultList,
  },

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
    drawerImage: {
      type: Boolean,
      default: false,
    },
    mini: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  emits: ["update:mini", "update:drawer"],

  setup(props, { emit }) {
    const internalMini = useVModel(props, "mini", emit);
    const internalDrawer = useVModel(props, "drawer", emit);

    return {
      internalMini,
      internalDrawer,
    };
  },
});
</script>
