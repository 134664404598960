// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";
import { es } from "vuetify/src/locale";

Vue.use(Vuetify, { directives: { Ripple } });

const theme = {
  primary: "#443486",
  secondary: "#ff8305",
  accent: "#D81B60 ",
  info: "#3498DB",
  success: "#4CAF50",
  warning: "#FFC107",
  error: "#E53935",
};

export default new Vuetify({
  breakpoint: { mobileBreakpoint: 960 },
  icons: {
    values: { expand: "mdi-menu-down" },
    iconfont: "fa",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: theme,
      light: theme,
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
