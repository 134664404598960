// Pinia Store
import { useAuth } from "@/auth";
import { APP_ROUTES } from "@/constants/app";

import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useAppStore = defineStore("app", () => {
  const drawer = ref(true);
  const drawerImage = ref(true);
  const mini = ref(false);
  const auth = useAuth();
  const user = computed(() => auth.user.value);
  const items = computed(() => {
    return APP_ROUTES.filter((route) => {
      if (route.roles) {
        return route.roles.some((role) => user.value?.roles.includes(role));
      }
      return true;
    });
  });
  return { drawer, drawerImage, mini, items };
});
