<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-btn
        class="ml-3 mr-4"
        outlined
        fab
        small
        @click="mini = !mini"
        v-on="on"
      >
        <v-icon>
          {{ mini ? "mdi-format-list-bulleted" : "mdi-dots-vertical" }}
        </v-icon>
      </v-btn>
    </template>

    <span>{{ mini ? "Versión completa" : "Versión mini" }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapWritableState } from "pinia";
import { useAppStore } from "@/stores/app";

export default defineComponent({
  name: "DefaultDrawerToggle",
  computed: {
    ...mapWritableState(useAppStore, ["mini"]),
  },
});
</script>
