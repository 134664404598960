<template>
  <v-main>
    <router-view :key="$route.path" />
  </v-main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "DefaultView",
});
</script>
