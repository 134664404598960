import Vue from "vue";
import { AuthPlugin, type AuthInstance } from "@/auth";
const apiUrl = import.meta.env.VITE_APP_API_URL;
export {};
declare module "vue" {
  interface ComponentCustomProperties {
    $auth: AuthInstance;
  }
}

Vue.use(AuthPlugin, {
  redirect: {
    login: "/login",
    logout: "/",
    callback: "/authorize-auth",
    home: "/",
  },
  strategy: {
    token: {
      property: "token",
      global: true,
      type: "Bearer",
      maxAge: 60 * 60 * 24 * 7,
    },
    user: {
      autoFetch: true,
    },
    endpoints: {
      login: { url: apiUrl + "/auth/signin", method: "post" },
      user: { url: apiUrl + "/user/me", method: "get" },
    },
  },
});
