import EmptyLayout from "@/layouts/empty/Index.vue";
import DefaultLayout from "@/layouts/default/Index.vue";

export default [
  {
    path: "",
    name: "DefaultLayout",
    component: DefaultLayout,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "dashboard" */ "../views/dashboard.vue"),
        children: [
          {
            path: "",
            redirect: "/daily",
          },
          {
            path: "daily",
            name: "Daily",
            component: () =>
              import(
                /* webpackChunkName: "daily" */ "../views/dashboard/daily.vue"
              ),
          },
          {
            path: "monthly",
            name: "IndexMonthly",
            component: () =>
              import(
                /* webpackChunkName: "index-monthly" */ "../views/dashboard/monthly.vue"
              ),
          },
          {
            path: "monthly/break-fixes",
            name: "MonthlyBreakFixes",
            component: () =>
              import(
                /* webpackChunkName: "monthly-break-fixes" */ "../views/dashboard/break-fixes.vue"
              ),
          },
          {
            path: "breakFixes",
            name: "BreakFixes",
            component: () =>
              import(
                /* webpackChunkName: "breakFixes" */ "../views/dashboard/break-fixes.vue"
              ),
          },
          {
            path: "duration",
            name: "Duration",
            component: () =>
              import(
                /* webpackChunkName: "monthly" */ "../views/dashboard/duration.vue"
              ),
          },
          {
            path: "monthly/effectivity",
            name: "effectivity",
            component: () =>
              import(
                /* webpackChunkName: "effectivity" */ "../views/dashboard/effectivity.vue"
              ),
          },
        ],
      },
      // Pages
      {
        path: "/quotes",
        component: () =>
          import(/* webpackChunkName: "quote-page" */ "../views/quotes.vue"),
        children: [
          {
            name: "QuotesIndex",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "quote-index" */
                "../views/quotes/index.vue"
              ),
          },
          {
            name: "QuoteId",
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "quote-id" */
                "../views/quotes/_id.vue"
              ),
          },
        ],
      },
      {
        path: "/orders",
        component: () =>
          import(/* webpackChunkName: "orders-page" */ "../views/orders.vue"),
        children: [
          {
            name: "OrdersIndex",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "orders-index" */ "../views/orders/index.vue"
              ),
          },
          {
            name: "OrdersId",
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "orders-id" */ "../views/orders/_id.vue"
              ),
          },
        ],
      },
      {
        path: "/tariffs",
        component: () =>
          import(/* webpackChunkName: "tariffs-page" */ "../views/tariffs.vue"),
      },

      {
        path: "/articles",
        component: () =>
          import(
            /* webpackChunkName: "articles-page" */ "../views/articles.vue"
          ),
      },
      {
        path: "/costs",
        component: () =>
          import(/* webpackChunkName: "costs-page" */ "../views/costs.vue"),
      },
      {
        path: "/tasks",
        component: () =>
          import(/* webpackChunkName: "tasks-page" */ "../views/tasks.vue"),
      },
      {
        path: "/users",
        component: () =>
          import(/* webpackChunkName: "users-page" */ "../views/users.vue"),
      },
      {
        path: "/calendar",
        component: () =>
          import(
            /* webpackChunkName: "removals-page" */ "../views/calendar.vue"
          ),
      },
      {
        path: "/visits",
        component: () =>
          import(/* webpackChunkName: "visits-page" */ "../views/visits.vue"),
      },
      {
        path: "/break-fixes",
        component: () =>
          import(
            /* webpackChunkName: "break-fixes-page" */ "../views/break-fixes.vue"
          ),
      },
      {
        path: "/packaging",
        component: () =>
          import(
            /* webpackChunkName: "break-fixes-page" */ "../views/packaging.vue"
          ),
      },
      {
        path: "/availability",
        component: () =>
          import(
            /* webpackChunkName: "break-fixes-page" */ "../views/availability.vue"
          ),
      },
      {
        path: "/reasons",
        component: () =>
          import(
            /* webpackChunkName: "break-fixes-page" */ "../views/non-order-reasons.vue"
          ),
      },
      {
        path: "/reports",
        component: () => import("../views/reports.vue"),
        children: [
          {
            name: "ReportsIndex",
            path: "",
            component: () => import("../views/reports/index.vue"),
          },
          {
            name: "ReportsArticleQuotes",
            path: "article-quotes",
            component: () => import("../views/reports/article-quotes.vue"),
          },
          {
            name: "ReportsArticleOrders",
            path: "article-orders",
            component: () => import("../views/reports/article-orders.vue"),
          },
          {
            name: "ReportPayments",
            path: "payments",
            component: () => import("../views/reports/payments.vue"),
          },
          {
            name: "ReportCostQuotes",
            path: "cost-quotes",
            component: () => import("../views/reports/cost-quotes.vue"),
          },

          {
            name: "ReportCustomers",
            path: "customers",
            component: () => import("../views/reports/customers.vue"),
          },
        ],
      },
      {
        path: "/expenditures",
        component: () => import("../views/expenditures/index.vue"),
      },
      {
        path: "/notifications",
        component: () => import("../views/notifications.vue"),
      },
    ],
  },
  {
    path: "",
    name: "EmptyLayout",
    component: EmptyLayout,
    children: [
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "login-page" */ "../views/login.vue"),
      },
    ],
  },
];
