import {
  type PluginObject,
  provide,
  type InjectionKey,
  inject,
  reactive,
} from "vue";
import type { IConfig } from "./interfaces";
import { createAuth } from "./composables";
import { instance, setInstance } from "./store";
import type { AuthInstance } from "./store";
export type { AuthInstance } from "./store";

/**
 *  Vue Plugin Definition
 */
export const authSymbol: InjectionKey<AuthInstance> = Symbol("auth");
export const AuthPlugin: PluginObject<IConfig> = {
  install: (Vue, options) => {
    const auth = createAuth(options);
    setInstance(auth);
    Vue.prototype.$auth = reactive({ ...auth });
    Vue.mixin({
      setup() {
        provide(authSymbol, auth);
      },
    });
  },
};

export const useAuth = () => {
  const auth = instance || inject(authSymbol);
  if (!auth) {
    throw new Error("No Auth0 instance found");
  }
  return auth;
};
