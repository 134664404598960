<template>
  <v-app-bar
    app
    absolute
    class="v-bar--underline"
    color="transparent"
    :clipped-left="$vuetify.rtl"
    :clipped-right="!$vuetify.rtl"
    height="70"
    flat
  >
    <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />

    <default-drawer-toggle class="hidden-sm-and-down" />

    <v-spacer />

    <default-go-home />

    <default-notifications />

    <default-account />
  </v-app-bar>
</template>

<script>
// Utilities
import { defineComponent } from "vue";
import { mapWritableState } from "pinia";
import { useAppStore } from "@/stores/app";

import DefaultAccount from "./widgets/Account.vue";
import DefaultDrawerToggle from "./widgets/DrawerToggle.vue";
import DefaultGoHome from "./widgets/GoHome.vue";
import DefaultNotifications from "./widgets/Notifications.vue";

export default defineComponent({
  name: "DefaultBar",

  components: {
    DefaultAccount,
    DefaultDrawerToggle,
    DefaultGoHome,
    DefaultNotifications,
  },

  computed: {
    ...mapWritableState(useAppStore, ["drawer", "mini"]),
  },
});
</script>
