<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container fill-height>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "EmptyLayout",
  data: () => ({
    links: ["Dashboard", "Messages", "Profile", "Updates"],
  }),
};
</script>
