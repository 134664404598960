import type { IConfig } from "./interfaces";

export const authDefaultOptions: IConfig = {
  redirect: {
    login: "/login",
    logout: "/",
    callback: "/authorize-auth",
    home: "/",
  },
  strategy: {
    token: {
      property: "data.token",
      global: true,
      type: "Bearer",
      maxAge: 60 * 60 * 24 * 7,
    },
    user: {
      property: "data",
      autoFetch: true,
    },
    endpoints: {
      login: {
        url: "",
        method: "post",
      },
      user: {
        url: "",
        method: "get",
      },
    },
  },
};
